import React from 'react'
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import  Layout from '../components/layout'
import  Seo from '../components/seo'
import { SectionContainer } from "../components/primitives";
import './thank-you.scss'

const ThankYouPage = ({ location }) => {

    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const eventStart =  new Date(params.event_start_time);
    const name = params.invitee_full_name;
    const email = params.invitee_email


    return (
        <Layout className='thank-you-page'>
            <Seo title='Thank You'/>
            <SectionContainer bgColor='white'>
                <h2>Thanks for Booking {name}!</h2>
                <p>We are booked for <Moment format="MMMM DD, YYYY hh:mma">{eventStart}</Moment></p>
                <p>Can't wait to talk with you! You will receive a confirmation email at {email}</p>
            </SectionContainer>
        </Layout>
    )
}

ThankYouPage.propTypes = {
    location: PropTypes.object
}

export default ThankYouPage
